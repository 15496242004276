<script setup lang="ts">
  import type { PloneDocument } from '~/types/Plone'
  import useComponentMapping from '~/composables/useComponentMapping'

  const props = withDefaults(defineProps<{
    columns?: number | string
    items?: PloneDocument[]
    large?: boolean
    showImage?: boolean
    showDescription?: boolean
    edit?: boolean
    matchHeight?: string | string[] | boolean
    align?: 'end' | 'start' | 'normal'
  }>(), { align: 'normal', edit: false })

  const sizesMapping = {
    1: 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw',
    2: 'xs:100vw sm:100vw md:50vw md:50vw lg:50vw xl:50vw xxl:50vw',
    3: 'xs:100vw sm:100vw md:50vw lg:22vw xl:18vw xxl:18vw',
    4: 'xs:100vw sm:100vw md:50vw lg:25vw xl:20vw xxl:20vw',
    5: 'xs:100vw sm:100vw md:15vw lg:15vw xl:15vw xxl:15vw',
    6: 'xs:100vw sm:100vw md:33vw lg:12vw xl:12vw xxl:12vw',
    7: 'xs:100vw sm:100vw md:10vw lg:10vw xl:10vw  xxl:10vw',
    8: 'xs:100vw sm:100vw md:10vw lg:10vw xl:10vw  xxl:10vw',
  }
</script>

<template>
  <app-grid
    :columns="columns"
    :align="align"
    :match-height="matchHeight"
    class="card-group">
    <template v-for="item in items">
      <component
        v-if="item"
        ref="itemsRefs"
        :key="item?.['@id']"
        :is="useComponentMapping(item)"
        :layout="columns > 1 ? 'column' : 'row'"
        :edit="edit"
        :show-image="showImage"
        :show-description="showDescription"
        :large="large"
        :sizes="sizesMapping[columns]"
        :data="item"/>
    </template>
  </app-grid>
</template>
